import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { Slot } from "@radix-ui/react-slot"
import {
  // Controller,
  // ControllerProps,
  // FieldPath,
  // FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form"

import { cn } from "@/lib/utils"
import { Label } from "@/components/ui/label"
import Icon from '../atoms/icon';
import Text from '../atoms/text';

// const Form = FormProvider

// type FormFieldContextValue<
//   TFieldValues extends FieldValues = FieldValues,
//   TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
// > = {
//   name: TName
// }

// const FormFieldContext = React.createContext<FormFieldContextValue>(
//   {} as FormFieldContextValue
// )



const useFormField = (name) => {
//   const fieldContext = React.useContext(FormFieldContext)
//   const itemContext = React.useContext(FormItemContext)
  // const { getFieldState, formState } = useFormContext()

  // const fieldState = getFieldState(fieldContext.name, formState)

  // if (!fieldContext) {
  //   throw new Error("useFormField should be used within <FormField>")
  // }

  const id = name;

  return {
    id,
    // name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    // ...fieldState,
  }
}

// type FormItemContextValue = {
//   id: string
// }

// const FormItemContext = React.createContext<FormItemContextValue>(
//   {} as FormItemContextValue
// )

const FormItem = React.forwardRef(({ className, ...props }, ref) => {
  // const id = name

  return (
    // <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn(className)} {...props} />
    // </FormItemContext.Provider>
  )
})
FormItem.displayName = "FormItem"

const FormLabel = React.forwardRef(({ className, name, ...props }, ref) => {
  const { error, formItemId } = useFormField(name)

  return (
    <Label
      ref={ref}
      className={cn(error && "text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  )
})
FormLabel.displayName = "FormLabel"

const FormControl = React.forwardRef(({name, ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField(name)
  
  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  )
})
FormControl.displayName = "FormControl"

const FormDescription = React.forwardRef(({ className, name, ...props }, ref) => {
  const { formDescriptionId } = useFormField(name)

  return (
    <Text id={formDescriptionId} type="span" variant="normal" className="first-letter:uppercase text-corporateGrey" ref={ref} {...props} />
  )
})
FormDescription.displayName = "FormDescription"

const FormMessage = React.forwardRef(({ className, name, children, meta: { info, touched, fieldEmpty, msg }, ...props }, ref) => {
  const { formMessageId } = useFormField(name)
  const body = info && msg ? 
                // Note: for info message
                (<>
                  <Icon name="info" className="w-6 h-6" />
                  <Text type="span" variant="info">{msg}</Text>
                </>) : touched && msg ?
                // Note: for error message
                (<>
                  <Icon name="error" />
                  <Text type="span" variant="error">{msg}</Text>
                </>) : fieldEmpty && msg ?
                (<>
                  <Icon name="warning" className="text-notificationsWarning"/>
                  <Text type="span" variant="warning">{msg}</Text>
                </>) :
                children;
  
  if (!body) {
    return null
  }

  return (
    <div
      ref={ref}
      id={formMessageId}
      className={cn(
        "flex gap-1", 
        // Note: for info message
        info && msg && "text-notificationsInfo",
        // Note: for error message
        touched && msg && "text-notificationsError",
        className)}
      {...props}
    >
      {body}
    </div>
  )
})
FormMessage.displayName = "FormMessage"

export {
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
}
